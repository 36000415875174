import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { trackEvent } from '../helpers/tracking'
import matchSorter from 'match-sorter'
import debounce from 'lodash.debounce'
import withStyles from '@material-ui/core/styles/withStyles'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SearchBar from 'material-ui-search-bar'
import PressingsTable from '../components/pressings-table'
import Pagination from '../components/pagination'
import queryString from 'query-string'

const styles = {
  iconButton: {
    display: 'none',
  },
  searchContainer: {
    display: 'flex',
    width: '100%',
  },
}

const DEFAULT_PAGE_SIZE = 100

class PressingsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      data: props.data,
      recordCodeQuery: '',
    }
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
  }

  componentDidMount() {
    this.focusMainSearch()
    const params = queryString.parse(this.props.location.search)
    const page = params.page ? params.page - 1 : 0
    if (params.recordCode) {
      // Page will get updated in updateQuery
      this.updateQuery(params.recordCode, 'recordCodeQuery', page)
    } else {
      // Set page manually
      if (page) {
        this.setState({ page })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const prevSearchParams = new URLSearchParams(prevProps.location.search); 
      const searchParams = new URLSearchParams(this.props.location.search); 
      if (searchParams.has("page") || (prevSearchParams.has("page") && !searchParams.has("page"))) {
        // Triggered by page change
        const currentPage = Number(searchParams.get("page"))
        const page = currentPage ? currentPage - 1 : 0
        this.setState({ page })
      }
    }
  }

  focusMainSearch() {
    const mainSearch = document.querySelectorAll('input[type=text]')[0]
    if (mainSearch) {
      mainSearch.focus()
    }
  }

  search(input, data, searchKeys) {
    if (input === '') {
      return data
    } else {
      const trimmed = input.trim()
      let first, rest
      if (/^("|')/.test(trimmed)) {
        const quote = trimmed.slice(0, 1)
        const afterQuote = trimmed.slice(1)
        const end = afterQuote.indexOf(quote)
        first = afterQuote.slice(0, end)
        rest = afterQuote
          .slice(end + 1)
          .trim()
          .split(' ')
      } else {
        ;[first, ...rest] = trimmed.split(' ')
      }
      return this.search(
        rest.join(' '),
        matchSorter(data, first, {
          keys: searchKeys,
          threshold: matchSorter.rankings.CONTAINS,
        }),
        searchKeys
      )
    }
  }

  trackSearch = debounce(
    (query, key) =>
      query &&
      trackEvent({
        category: 'Original Labels',
        action: `Search - ${key}`,
        label: query,
      }),
    2000
  )

  updateQuery(query, key, page = 0) {
    this.trackSearch(query, key)
    if (this.state.page !== 0) {
      this.handlePageChange(0)
    }
    this.setState({ [key]: query, page }, () => {
      const filteredByRecordCode = this.search(
        this.state.recordCodeQuery,
        this.props.data,
        [
          {
            threshold: matchSorter.rankings.MATCHES,
            key: 'recordCode',
          },
        ]
      )
      this.setState({ data: filteredByRecordCode })
    })
  }

  handleSearch = debounce(this.updateQuery, 200)

  resetSearch(key) {
    if (key) {
      // Single field cleared
      this.updateQuery('', key)
    } else {
      // Reset button clicked
      this.setState({
        data: this.props.data,
        recordCodeQuery: '',
        page: 0,
      })
      trackEvent({ category: 'Original Labels', action: 'Reset search' }) 
      this.props.navigate('/original-labels#search')
      /* if (this.props.location.search) {
        // Clear params first (redircts back here)
        this.props.navigate('/original-labels')
      } else {
        // Final clear search
        this.setState({
          data: this.props.data,
          recordCodeQuery: '',
          page: 0,
        })
        trackEvent({ category: 'Original Labels', action: 'Reset search' })
      } */
    }
  }

  handlePageChange(index) {
    const page = index + 1
    const params = { ...queryString.parse(this.props.location.search) }
    if (page === 1) {
      delete params.page
    } else {
      params.page = page
    }
    const strParams = Object.keys(params).length
      ? `?${queryString.stringify(params)}`
      : ''
    this.props.navigate(`/original-labels${strParams}#search`)
    trackEvent({ category: 'Original Labels', action: 'Paginate', label: page })
  }

  handlePageSizeChange(pageSize) {
    this.setState({ pageSize })
    trackEvent({
      category: 'Original Labels',
      action: 'Change page size',
      label: pageSize,
    })
  }

  render() {
    const paginationProps = {
      page: this.state.page + 1,
      pages: Math.ceil(this.state.data.length / this.state.pageSize),
      onSelect: (page, event) => {
        event.preventDefault()
        this.handlePageChange(page - 1)
      },
    }
    const searchBarStyles = {
      boxShadow:
        '0px 1px 5px 0px rgba(0,0,0,0.3), 0px 2px 2px 0px rgba(0,0,0,0.3), 0px 3px 1px -2px rgba(0,0,0,0.3)',
      borderRadius: '25px',
    }
    return (
      <Layout>
        <SEO title="Original Labels" />
        <h1>Original labels resource</h1>
        <p>
          It can be hard to be sure of the original label for many of the
          collectable English Golden Age LPs, so I'm providing this free
          public resource to help people find the correct information.
        </p>
        <p>
          You can search by record code, to see what the original label for
          a particular record was.
        </p>
        <p>
          So far this resource contains information on the original labels 
          for: 
          <table className='label-table'>
            <tr><th>Company</th><th>Record codes</th></tr>
            <tr><td>Columbia</td><td>SAX</td></tr>
            <tr><td>Decca</td><td>SXL 2001-6499, SET 201-400 and LXT 5000-5200</td></tr>
            <tr><td>HMV</td><td>ASD 251-2500 and SAN 101-251</td></tr>
            <tr><td>RCA</td><td>SB 2001-6680</td></tr>
          </table>
        </p>
        <p>
          Over time, I plan to add information on other labels, and you can{' '}
          <a href="http://eepurl.com/gbnRyz">join my mailing list</a> to
          receive my monthly newsletter which will tell you when I've added
          more information.
        </p>
        <p>
          If you'd like to learn more about labels and pressings, see my{' '}
          <Link to="/guide-to-collecting">Guide to collecting</Link>, or you
          can visit my <Link to="/stock-list">stock list</Link> to see what
          LPs I have available.
        </p>
        <Grid container spacing={24} style={{ marginBottom: 20 }}>
          <Grid item xs={8} sm={7} md={6}>
            <p id="search" style={{ marginBottom: 5 }}>Search by record code:</p>
            <SearchBar
              value={this.state.recordCodeQuery}
              onChange={query =>
                this.handleSearch(query, 'recordCodeQuery')
              }
              onCancelSearch={() => this.resetSearch('recordCodeQuery')}
              cancelOnEscape
              placeholder="Record code"
              classes={{
                iconButton: this.props.classes.iconButton,
                searchContainer: this.props.classes.searchContainer,
              }}
              style={searchBarStyles}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={5}
            md={6}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <div style={{ display: 'flex', height: 48 }}>
              <Button
                onClick={() => this.resetSearch()}
                color="secondary"
                variant={'outlined'}
              >
                Reset
              </Button>
            </div>
          </Grid>
        </Grid>
        <PressingsTable
          data={this.state.data}
          page={this.state.page}
          onPageChange={this.handlePageChange}
          pageSize={this.state.pageSize}
          onPageSizeChange={this.handlePageSizeChange}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          shouldCollapse={this.state.shouldCollapse}
        />
        <Pagination {...paginationProps} />
      </Layout>
    )
  }
}

const WrappedPressingsList = withMobileDialog()(
  withStyles(styles)(PressingsList)
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        postgres {
          allPressingsList {
            recordCode
            firstPressing
            notes
          }
          allStocksList {
            recordCode
          }
        }
      }
    `}
    render={data => (
      <WrappedPressingsList {...props} data={data.postgres.allPressingsList} />
    )}
  />
)
