import React from 'react'
import Table from 'react-table'
import naturalSort from '../helpers/natural-sort'
import 'react-table/react-table.css'

const PressingsTable = ({ data = [], ...rest }) => (
  <Table
    data={data}
    defaultSorted={[{ id: 'recordCode' }]}
    defaultSortMethod={naturalSort}
    columns={[
      {
        Header: 'Record code',
        accessor: 'recordCode',
        resizable: false,
      },
      {
        Header: 'Original Label',
        accessor: 'firstPressing',
        minWidth: 150,
        headerClassName: 'header-cell',
        resizable: false,
      },
      {
        id: 'notes',
        Header: 'Notes',
        accessor: 'notes',
        resizable: false,
        minWidth: 400,
        style: {
          overflow: 'auto',
          textOverflow: 'inherit',
        },
      },
    ]}
    className="-striped -highlight stock-list__table"
    style={{
      backgroundColor: '#fff',
    }}
    getTheadThProps={() => ({
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    })}
    getTrProps={() => ({
      style: {
        display: 'flex',
        alignItems: 'center',
      },
    })}
    showPaginationTop
    showPaginationBottom
    rowsText="records per page"
    noDataText="No records found"
    minRows={5}
    showPageJump={false}
    pageSizeOptions={[50, 100, 250, 500]}
    {...rest}
  />
)

export default PressingsTable
